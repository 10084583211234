import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';

import { useEffect, useState } from 'react';
// @mui
import {
  Dialog, DialogTitle, DialogContent, DialogActions ,
  Alert,
  Snackbar,
  Card,
  Table,
  Stack,
  Paper,
  TextField,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Switch,
  Modal
} from '@mui/material';
// components


import { useNavigate } from 'react-router-dom';
import { Delete, Visibility, Edit } from '@mui/icons-material';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
// import USERLIST from '../_mock/user';
import {  sendinvite,getUsers ,deleteUser} from '../service/user.service';

import { imgURL } from '../service/config';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'profile.image.file', label: 'Image', alignRight: false },
  { id: 'profile.name', label: 'Name', alignRight: false },
  { id: 'isCompleteProfile', label: 'Role', alignRight: false },
  { id: 'userType', label: 'Phone', alignRight: false },
  { id: 'status', label: 'Email', alignRight: false },
  { id: '_id' },
];

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}



function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.profile?.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}




  
export default function UserPage() {
  const navigate = useNavigate();






  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  
  const [SelectedDeleteUser, setSelectedDeleteUser] = useState(null);

  
  const [id, setid] = useState('');
  const [open, setOpen] = useState(null);
  const [data, setdata] = useState([]);

  const [pendingUser, setpendingUser] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  

  const handleOpenMenu = (event) => {
    setid(event.currentTarget.value);
    setOpen(event.currentTarget);
  };
  const handlependingUser = async () => {
    const deletedata = await pendingUser(id);
    if (deletedata.message === 'Unauthorized') {
      localStorage.setItem('token', '');
      navigate('/');
    }
    getdata();
  };

  const handleCloseMenu = () => {
    setid('');
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const [email, setEmail] = useState('');
  
 
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
 
 


  // const handleDeleteRow = async (row) => {
    
  //   try {
  //     await deleteUser(row);
  //     getdata();
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };


  const handleDeleteConfirmationClose = () => {
    if (deleteConfirmationOpen) {
      setDeleteConfirmationOpen(false);
      // Perform deletion logic here
      // You can use the selected user IDs in the `selected` state variable for deletion
      
    }
  };


  const handleDeleteConfirmationProceed = async () => {
    try {
      await deleteUser(SelectedDeleteUser);
      getdata();
    } catch (error) {
      console.error(error);
    }
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteRow = (row) => {
    setSelectedDeleteUser(row);
    handleOpenDeleteConfirmation();
  };
  
  const handleOpenDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };
  
  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };
  

 
  const handleSendInvite = async () => {
    try {
      const payload = {
        email
        // Add other payload data if needed
      };

      const response = await sendinvite(payload);
      setIsSnackbarOpen(true); // Open the success Snackbar
      setSnackbarSeverity('success');
      setSnackbarMessage('Invitation sent successfully!');

      console.log(response); // Handle the response data
      handleCloseModal()
      setEmail('')
      // TODO: Handle success or display a success message
    } catch (error) {
      console.error(error); // Handle the error


      setIsSnackbarOpen(true); // Open the error Snackbar
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to  Invitation.');
      // TODO: Handle error or display an error message
    }

  }

  const handleCloseSnackbar = () => {
    setIsSnackbarOpen(false); // Close the Snackbar
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  // const userdata = useSelector((state) => state.userListState);
  const getdata = async () => {
    const resp = await getUsers();
    console.log(resp.data)
    if (resp.message === 'Unauthorized') {
      localStorage.setItem('token', '');
      navigate('/');
    }

    if (resp.status === true) {
      if (pendingUser) {
        setdata(
          resp.data.filter((item) => {
            return item.status === "pending";
          })
        );
      } else {
        
        setdata(
          resp.data.filter((item) => {
            return item.status === "accepted";
          })
        );
      }
    }
// console.log("userdata",userdata)
// console.log("data",data)
// console.log("THE TOKEN",localStorage.getItem("token"))
  };
  useEffect(() => {
    getdata();

  }, [pendingUser]);

  return (
    <>
      <Helmet>
        <title> User </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          
        <Button onClick={handleOpenModal}>Send Invitation</Button>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Send Invitation
          </Typography>
          <br/>
          <TextField   
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email" fullWidth label="User Email" id="fullWidth" />

          <Button style={{float:'right',marginTop:'20px'}} onClick={handleSendInvite}>Send</Button>

        </Box>
      </Modal>

      <Dialog open={deleteConfirmationOpen} onClose={handleCloseDeleteConfirmation}>
  <DialogTitle>Delete User</DialogTitle>
  <DialogContent>
    <Typography>Are you sure you want to delete the selected user(s)?</Typography>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleDeleteConfirmationClose}>Cancel</Button>
    <Button onClick={handleDeleteConfirmationProceed} color="error" variant="contained">
      Delete
    </Button>
  </DialogActions>
</Dialog>


      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
          
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <Box component={'div'}>
            <Typography variant="p" gutterBottom>
              Pending Users
            </Typography>
            <Switch
              checked={pendingUser}
              onChange={() => {
                setpendingUser(!pendingUser);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    // const { _id, name, role, status, company, avatarUrl, isVerified } = row;
                    const selectedUser = selected.indexOf(row._id) !== -1;

                    return (
                      <TableRow hover key={row._id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, row._id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {row.profile?.image?.file ? (
                              <Box
                                sx={{ width: 150, height: 150 }}
                                component="img"
                                alt={row.profile?.name}
                                src={imgURL + row.profile?.image?.file}
                              />
                            ) : (
                              <Avatar
                                style={{ margin: '12px' }}
                                sx={{
                                  bgcolor: stringToColor(row.profile?.name),
                                }}
                                children={
                                  row.profile?.name.split(' ').length > 1
                                    ? `${row.profile?.name.split(' ')[0][0]}${
                                        row.profile.name.split(' ')[1][0]
                                      }`
                                    : `${row.profile?.name.split(' ')[0][0]}`
                                }
                              />
                            )}
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {row.name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{row.userType}</TableCell>

                        {/* <TableCell align="left">{row.isCompleteProfile ? 'Complete' : 'Pending'}</TableCell> */}
                        <TableCell align="left">{row.phone}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>

                        {/* <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell> */}

                        <TableCell align="right">
                        <IconButton onClick={() => handleDeleteRow(row._id)} aria-label="delete">
            <Delete />
          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

    
    </>
  );
}
