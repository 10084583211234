import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  Modal,
  CardActions,
  Box,
  TextareaAutosize,
} from '@mui/material';

import { getPrivacy, getTerms, updatePrivacy, updateTerms } from '../service/user.service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '50%',
  height: '50%',
  right: 'auto',
  bottom: 'auto',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ContentPage() {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [terms, setTerms] = useState('');
  const [privacy, setPrivacy] = useState('');
  const [payload, setPayload] = useState({
    privacypolicy: '',
    termscondition: '',
  });

  const fetchData = async () => {
    const privacyData = await getPrivacy();
    setPrivacy(privacyData[0].title);

    const termsData = await getTerms();
    setTerms(termsData[0].title);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePayloadUpdate = (e) => {
    const { value } = e.target;
    if (payload.termscondition !== '') {
      setPayload({ termscondition: value, privacypolicy: '' });
    } else {
      setPayload({ termscondition: '', privacypolicy: value });
    }
  };

  const updateContent = async () => {
    if (payload.termscondition !== '') {
      const resp = await updateTerms({ title: payload.termscondition });
      if (resp.status === true) {
        setOpen(false);
        setPayload({ privacypolicy: '', termscondition: '' });
        fetchData();
      }
    } else {
      const resp = await updatePrivacy({ title: payload.privacypolicy });
      if (resp.status === true) {
        setOpen(false);
        setPayload({ privacypolicy: '', termscondition: '' });
        fetchData();
      }
    }
  };

  const handlePrivacy = () => {
    setOpen(true);
    setPayload({ privacypolicy: privacy, termscondition: '' });
  };

  const handleTerms = () => {
    setOpen(true);
    setPayload({ privacypolicy: '', termscondition: terms });
  };

  return (
    <Container>
      <Typography variant="h4" sx={{ mb: 5 }}>
        Content
      </Typography>
      <Card>
        <CardContent>
          <Typography sx={{ fontSize: 24 }} color="text.secondary" gutterBottom>
            Terms And Conditions
          </Typography>
          <Typography variant="body2">{terms}</Typography>
        </CardContent>
        <CardActions sx={{ float: 'right' }}>
          <Button size="large" onClick={handleTerms}>
            Edit Terms And Conditions
          </Button>
        </CardActions>
      </Card>

      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Typography sx={{ fontSize: 24 }} color="text.secondary" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="body2">{privacy}</Typography>
        </CardContent>
        <CardActions sx={{ float: 'right' }}>
          <Button size="large" onClick={handlePrivacy}>
            Edit Privacy And Policy
          </Button>
        </CardActions>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {payload.privacypolicy !== '' ? 'Privacy Policy' : 'Term And Condition'}
          </Typography>
          <TextareaAutosize
            placeholder={payload.privacypolicy !== '' ? 'Privacy Policy' : 'Term And Condition'}
            onChange={handlePayloadUpdate}
            style={{
              width: '90%',
              height: 'auto',
              marginLeft: 10,
              border: '1px solid blue',
              borderRadius: 20,
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              padding: '6px',
              marginTop: '10px',
            }}
            rows={12}
            value={payload.privacypolicy !== '' ? payload.privacypolicy : payload.termscondition}
          />
          <Button onClick={updateContent}>Update</Button>
        </Box>
      </Modal>
    </Container>
  );
}
