import { useEffect } from 'react';

import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import PostPage from './pages/PostPage';
import DashboardAppPage from './pages/DashboardAppPage';
import ContentPage from './pages/ContentPage';
import NotificationPage from './pages/NotificationPage';
import Meal from './pages/Meal';
import ContactForm from './pages/ContactForm';
import Designation from './pages/Designation';
import Event from './pages/Events';
import Exercise from './pages/Exercise';
import RequestPage from './pages/RequestPage';
import Diet from './pages/Diet';
import Nutrition from './pages/Nutrition';
import Routine from './pages/Routine';
import Document from './pages/Documents';
import Magazine from './pages/Magazine';
import Video from './pages/Videos';


// ----------------------------------------------------------------------

export default function Router() {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      navigate('/login');
      console.log("1");

    }else{
      console.log("2");
    }
  }, []);
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'post', element: <PostPage /> },
        { path: 'content', element: <ContentPage /> },
        { path: 'subscription', element: <PostPage /> },
        { path: 'notification', element: <NotificationPage /> },
        { path: 'request', element: <RequestPage /> },
        { path: 'meal', element: <Meal /> },
        { path: 'document', element: <Document /> },
        { path: 'contactform', element: <ContactForm /> },
        { path: 'magazine', element: <Magazine /> },
        { path: 'videos', element: <Video /> },
        { path: 'events', element: <Event /> },
        { path: 'designation', element: <Designation/> },
       



      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
