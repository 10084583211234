import { Action } from './config';

export const AdminLogin = async (body) => {
  const data = await Action.post('/login', body);
localStorage.setItem("adminName",data.data.data.name)
localStorage.setItem("adminEmail",data.data.data.email)

  return data.data;

};
export const getUsers = async () => {
  
  const data = await Action.get('/admin/getallusers');

  return data.data;
};
export const deleteUser = async (id) => {
  const data = await Action.patch(`/admin/deleteuser/${id}`);

  return data.data;
};

export const getTerms = async () => {
  const data = await Action.get(`/admin/terms`);
  return data.data.data;
};

export const getPrivacy = async () => {
  const data = await Action.get(`/admin/privacy`);
  return data.data.data;
};
export const updateTerms = async (payload) => {
  const data = await Action.post(`/admin/terms`, payload);
  return data.data;
};

export const updatePrivacy = async (payload) => {
  const data = await Action.post(`/admin/privacy`, payload);
  return data.data;
};
export const getdashboard = async () => {
  const data = await Action.get(`/admin/usercount`);
  return data.data;
};




export const sendinvite = async (payload) => {
  const data = await Action.post(`/admin/register`, payload);
  return data.data;
};


export const addDesignation = async (payload) => {
  const data = await Action.post(`/admin/designation`, payload);
  return data.data;
};
export const getDesignation = async () => {
  const data = await Action.get(`/admin/designation`);
  return data.data;
};


export const deleteDesignation = async (id) => {
  const data = await Action.delete(`/admin/designation/${id}`);
  return data.data;
};



