import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, Edit, GetApp, Visibility } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import { imgURL } from '../service/config';
import { addVideo, deleteVideo, updateVideo, getVideo } from '../service/ContentUploads';

const Video = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [cimage, setCimage] = useState(null);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [videoToDelete, setVideoToDelete] = useState(null);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [submitting, setSubmitting] = useState(false); // Add submitting state

  const getdata = async () => {
    try {
      const resp = await getVideo();
      console.log(resp.data);
      setTableData(
        resp.data.map((item) => {
          return { ...item, userName: item.user.name };
        })
      );
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error(error);
      setLoading(false); // Set loading to false even if an error occurs
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    try {
      if (values.examples) {
        values.examples = values.examples.split(',');
      }
      await updateVideo(tableData[row.index]._id, values);
      getdata();
      exitEditingMode();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteConfirmationOpen = (row) => {
    setVideoToDelete(row);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setVideoToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteRow = async () => {
    try {
      await deleteVideo(videoToDelete._id);
      getdata();
      handleDeleteConfirmationClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleVideoUpload = async () => {
    try {
      // Check if any field is empty
      if (!title || !file || !cimage) {
        setErrorPopupOpen(true); // Open the error popup modal
        return;
      }
      setCreateModalOpen(false);
      setSubmitting(true); // Start submitting data, show loader

      const formData = new FormData();
      formData.append('title', title);
      formData.append('files', file);
      formData.append('files', cimage);

      const response = await addVideo(formData);
      console.log(response);
      getdata();
   
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false); // Stop submitting data, hide loader whether it's success or error
    }
  };

  const columns = [
    {
      name: 'Title',
      selector: 'title',
      sortable: false,
    },
    {
      name: 'File',
      sortable: false,
      cell: (row) => (
        <>
          <video width="320" height="240" controls>
            <source src={imgURL + row.file[0]} type="video/mp4" />
            Your browser does not support the video tag.
            <track kind="captions" />
          </video>
        </>
      ),
    },
    {
      name: 'Actions',
      sortable: false,
      cell: (row) => (
        <Box sx={{ display: 'flex', gap: '1rem', float: 'right', ml: 20 }}>
          <Tooltip arrow placement="right" title="Delete">
            <IconButton color="error" onClick={() => handleDeleteConfirmationOpen(row)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleCoverImageChange = (event) => {
    setCimage(event.target.files[0]);
  };

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const closeErrorPopup = () => {
    setErrorPopupOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Videos</title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Videos
        </Typography>
      </Container>

      <Box sx={{ mt: 2, mb: 5 }}>
        <Button color="secondary" onClick={() => setCreateModalOpen(true)} variant="contained">
          Upload New Video
        </Button>
      </Box>

      {loading || submitting ? ( // Show loader if loading or submitting is true
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      ) : (
        // Render the table when loading and submitting are false
        <DataTable
          columns={columns}
          data={tableData}
          pagination
          highlightOnHover
          noHeader
          dense
          responsive
          customStyles={{
            headRow: {
              style: {
                borderTop: 'none',
              },
            },
            headCells: {
              style: {
                fontSize: '14px',
                fontWeight: 'bold',
                color: '#000',
                backgroundColor: '#f5f5f5',
              },
            },
            cells: {
              style: {
                fontSize: '14px',
                color: '#000',
              },
            },
          }}
        />
      )}

      <Dialog open={deleteConfirmationOpen} onClose={handleDeleteConfirmationClose}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this video?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmationClose}>Cancel</Button>
          <Button onClick={handleDeleteRow} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={errorPopupOpen} onClose={closeErrorPopup}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography>Please fill in all the fields.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeErrorPopup} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <CreateNewVideoModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleVideoUpload}
        onFileChange={handleFileChange}
        onChangeTitle={handleChangeTitle}
        onCoverChange={handleCoverImageChange}
      />
    </>
  );
};

const CreateNewVideoModal = ({ open, onClose, onSubmit, onFileChange, onChangeTitle, onCoverChange }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create New Video</DialogTitle>
      <br />

      <DialogContent>
        <p>Please upload Video in "docx", "docx", "pdf", "txt" Format</p>
        <form onSubmit={handleSubmit}>
          <Stack sx={{ width: '100%', minWidth: { xs: '300px', sm: '360px', md: '400px' }, gap: '1.5rem' }}>
            <TextField id="outlined-controlled" label="Video Name" name="title" onChange={onChangeTitle} />
            <Typography variant="h5" component="h2">
              Add Video
            </Typography>
            <input type="file" onChange={onFileChange} />

            <Typography variant="h5" component="h2">
              Add Cover Image
            </Typography>
            <input type="file" accept=".png,.jpg,.jpeg" onChange={onCoverChange} />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Create New Video
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Video;
