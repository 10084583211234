import React, { useEffect, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, Edit, GetApp, Visibility } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import { imgURL } from '../service/config';
import { addMagazine, deleteMagazine, updateMagazine, getMagazine } from '../service/ContentUploads';

const Magazine = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [file, setFile] = useState(null);
  const [cimage, setCimage] = useState(null);
  const [title, setTitle] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [submitting, setSubmitting] = useState(false); // Add submitting state

  const getdata = async () => {
    try {
      const resp = await getMagazine();
      console.log(resp.data);
      setTableData(
        resp.data.map((item) => {
          return { ...item, userName: item.user.name };
        })
      );
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error(error);
      setLoading(false); // Set loading to false even if an error occurs
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    try {
      if (values.examples) {
        values.examples = values.examples.split(',');
      }
      await updateMagazine(tableData[row.index]._id, values);
      getdata();
      exitEditingMode(); // required to exit editing mode and close modal
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteConfirmationOpen = (row) => {
    setRowToDelete(row);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
    setRowToDelete(null);
  };

  const handleDeleteRow = async () => {
    try {
      if (rowToDelete) {
        await deleteMagazine(tableData[rowToDelete.index]._id);
        getdata();
        handleDeleteConfirmationClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleMagazineUpload = async () => {
    try {
      // Check if any field is empty
      if (!title || !file || !cimage) {
        setError('Please fill in all the fields');
        return;
      }
      setCreateModalOpen(false);
      setSubmitting(true); // Start submitting data, show loader

      const formData = new FormData();
      formData.append('title', title);
      formData.append('files', file);
      formData.append('files', cimage);

      const response = await addMagazine(formData);
      console.log(response);
      getdata();
     // Close the modal after successful upload
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false); // Stop submitting data, hide loader whether it's success or error
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleCoverImageChange = (event) => {
    setCimage(event.target.files[0]);
  };

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleCloseError = () => {
    setError(null);
  };

  const columns = [
    {
      accessorKey: 'title',
      header: 'Title',
      size: 140,
      enableColumnOrdering: false,
      enableSorting: false,
    },
    {
      accessorKey: 'file',
      header: 'File',
      enableColumnOrdering: false,
      enableSorting: false,
      size: 10,
      type: 'file',
      Cell: ({ cell }) => (
        <>
          <IconButton
            component={Link}
            href={imgURL + cell.row.original.file[0]}
            download
            color="primary"
            aria-label="Download File"
          >
            <GetApp />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Magazines</title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Magazines
        </Typography>
      </Container>

      {loading || submitting ? ( // Show loader if loading or submitting is true
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      ) : (
        // Render the table when loading and submitting are false
        <MaterialReactTable
          columns={columns}
          data={tableData}
          editingMode="modal"
          enableEditing
          onEditingRowSave={handleSaveRowEdits}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handleDeleteConfirmationOpen(row)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          renderTopToolbarCustomActions={() => (
            <Button color="secondary" onClick={() => setCreateModalOpen(true)} variant="contained">
              Upload New Magazine
            </Button>
          )}
        />
      )}

      <CreateNewMagazineModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleMagazineUpload}
        onFileChange={handleFileChange}
        onChangeTitle={handleChangeTitle}
        onCoverChange={handleCoverImageChange}
      />

      <DeleteConfirmationDialog
        open={deleteConfirmationOpen}
        onClose={handleDeleteConfirmationClose}
        onConfirm={handleDeleteRow}
      />

      <Dialog open={error !== null} onClose={handleCloseError}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography>{error}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseError} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CreateNewMagazineModal = ({ open, onClose, onSubmit, onFileChange, onChangeTitle, onCoverChange }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create New Magazine</DialogTitle>
      <br />

      <DialogContent>
        <p>Please upload Magazine in "docx", "docx", "pdf", "txt" Format</p>
        <form onSubmit={handleSubmit}>
          <Stack sx={{ width: '100%', minWidth: { xs: '300px', sm: '360px', md: '400px' }, gap: '1.5rem' }}>
            <TextField id="outlined-controlled" label="Magazine Name" name="title" onChange={onChangeTitle} />
            <Typography variant="h5" component="h2">
              Add Magazine
            </Typography>
            <input type="file" accept=".pdf,.doc,.docx" onChange={onFileChange} />

            <Typography variant="h5" component="h2">
              Add Cover Image
            </Typography>
            <input type="file" accept=".png,.jpg,.jpeg" onChange={onCoverChange} />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Create New Magazine
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteConfirmationDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this magazine?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Magazine;
