// ----------------------------------------------------------------------
const adminName = localStorage.getItem("adminName") 
const adminEmail = localStorage.getItem("adminEmail")
const account = {
  displayName:adminName ,
  email:adminEmail ,
  photoURL: '/assets/images/avatars/admin.jpeg',
};

export default account;
