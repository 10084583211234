import axios from "axios"



// const baseUrl= "https://gymsta-api.jumppace.com:9000/api/v1/"
// export const imgURL = "https://gymsta-api.jumppace.com:9000/"
 const baseUrl = "https://uaw-api.thesuitchstaging.com:3090/api/v1"
 export const imgURL = "https://uaw-api.thesuitchstaging.com:3090/"
 export const DownloadUrl = "/api/v1/documents/"

export const Action  = axios.create({    
    baseURL: baseUrl,  
    "Content-Type": "application/json",
    headers: {
        Authorization:`${localStorage.getItem("token")}`        
    }
  });
  