import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Avatar from '@mui/material/Avatar';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  TextareaAutosize,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  Grid,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  ListItemText,
  List,
  ListItem,
  MenuItem,
  Select,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
  ListItemAvatar,
} from '@mui/material';
import { Delete, Visibility, Edit } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import { imgURL } from '../service/config';
import {
  addEvent,
  deleteEvent,
  getSingleEvent,
  updateEvent,
  getEvent,
} from '../service/ContentUploads';

const Event = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [desc, setDesc] = useState('');
  const [location, setLocation] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editTitle, setEditTitle] = useState('');
  const [editId, setEditId] = useState('');
  const [editDate, setEditDate] = useState('');
  const [editDesc, setEditDesc] = useState('');
  const [editLocation, setEditLocation] = useState('');
  const [editFile, setEditFile] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);


  const getdata = async () => {
    try {
      const resp = await getEvent();
      setTableData(resp.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    try {
      if (values.examples) {
        values.examples = values.examples.split(',');
      }
      await updateEvent(tableData[row.index]._id, values);
      getdata();
      exitEditingMode();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteRow = (row) => {
    setSelectedEvent(row);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteConfirmationProceed = async () => {
    try {
      await deleteEvent(selectedEvent._id);
      getdata();
    } catch (error) {
      console.error(error);
    }
    setDeleteConfirmationOpen(false);
  };

  const handleSingleEvent = async (row) => {
    try {
      const response = await getSingleEvent(row._id);
      console.log(response.data)
      setModalContent(response.data);
      setModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEventUpload = async () => {
    try {
      setSaveButtonDisabled(true);
      const formattedTime = new Date(time.$d).toLocaleTimeString('en-US');
      const formData = new FormData();
      formData.append('title', title);
      formData.append('file', file);
      formData.append('date', date);
      formData.append('time', formattedTime);
      formData.append('description', desc);
      formData.append('location', location);

      const response = await addEvent(formData);
      getdata();
      setCreateModalOpen(false);
    setShowSuccessModal(true); // Show success modal
      setCreateModalOpen(false);
    } catch (error) {
      console.error(error);
    }finally {
      setSaveButtonDisabled(false); // Enable the button again after completing the request (success or error)
    }
  };

  const handleEditRow = async (row) => {
    setEditModalOpen(true);
    console.log(row)
     setEditId(row._id)
     setEditTitle(row.title);
     // setEditDate(row.date);
    // console.log(new Date(row.date))
     setEditDesc(row.description);
     setEditLocation(row.location);

  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpdateEvent = async () => {
    try {
      const formData = new FormData();
      formData.append('title', editTitle);
      formData.append('date', editDate);
      formData.append('description', editDesc);
      formData.append('location', editLocation);
      formData.append('file', editFile);

      // const uid = await selectedEvent._id
      
      const response = await updateEvent(editId, formData);
      console.log("updated",response)
      getdata();
      setEditModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const SuccessModal = () => (
    <Dialog open={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
      <DialogTitle>Event Created</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The event has been successfully created.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowSuccessModal(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
  

  const columns = [
    {
      name: 'Title',
      selector: 'title',
      sortable: true,
    },
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      format: (row) => new Date(row.date).toLocaleString(),
    },
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
      maxWidth: '400px',
    },
    {
      name: 'Location',
      selector: 'location',
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          <IconButton onClick={() => handleSingleEvent(row)} aria-label="view">
            <Visibility />
          </IconButton>
          <IconButton onClick={() => handleEditRow(row)} aria-label="edit">
            <Edit />
          </IconButton>
          <IconButton onClick={() => handleDeleteRow(row)} aria-label="delete">
            <Delete />
          </IconButton>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Events</title>
        <meta name="description" content="Events Page" />
      </Helmet>

      <Container maxWidth="lg">
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCreateModalOpen(true)}
          >
            Add Event
          </Button>
        </Box>

        <DataTable
          columns={columns}
          data={tableData}
          pagination
          highlightOnHover
          noHeader
          dense
          responsive
          customStyles={{
            headRow: {
              style: {
                borderTop: 'none',
              },
            },
            headCells: {
              style: {
                fontSize: '14px',
                fontWeight: 'bold',
                color: '#000',
                backgroundColor: '#f5f5f5',
              },
            },
            cells: {
              style: {
                fontSize: '14px',
                color: '#000',
              },
            },
          }}
        />

        <Modal open={createModalOpen} onClose={() => setCreateModalOpen(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
          >
            <h2 id="create-modal-title">Add Event</h2>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  variant="outlined"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>


                <DemoContainer components={['DatePicker']}>
        <DatePicker  label="Date"
                    value={date}
                    onChange={(newValue) => setDate(newValue)}
                    renderInput={(props) => <TextField {...props} />}  />
      </DemoContainer>

             
                
                </LocalizationProvider>



                
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
             
                   <TimeField value={time}
                    onChange={(newValue) => setTime(newValue)} label="Time" />

                  
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  minRows={3}
                  placeholder="Description"
                  style={{ width: '100%', padding: '10px' }}
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Location"
                  variant="outlined"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <input type="file" onChange={handleFileChange} />
              </Grid>
            </Grid>
            <Button variant="contained" color="primary"   disabled={saveButtonDisabled}  onClick={handleEventUpload}>
              Save
            </Button>
          </Box>
        </Modal>

        <SuccessModal /> 

        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>{modalContent?.title}</DialogTitle>
          <DialogContent>
            <Box display="flex">
              <Box>
                <img
                  src={`${imgURL}/${modalContent?.file[0].file}`}
                  alt={modalContent?.title}
                  style={{ width: '100%' }}
                />
              </Box>
              <Box ml={2}>
                <Typography variant="body1" color="textSecondary">
                  {new Date(modalContent?.date).toLocaleString()}
                </Typography>
                <Typography variant="body1">{modalContent?.description}</Typography>
                <Typography variant="body1">{modalContent?.location}</Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>


        <Dialog
          open={deleteConfirmationOpen}
          onClose={handleDeleteConfirmationClose}
        >
          <DialogTitle>Delete Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Are you sure you want to delete this event ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteConfirmationClose}>Cancel</Button>
            <Button onClick={handleDeleteConfirmationProceed} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 800,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
          >
            <h2 id="edit-modal-title">Edit Event</h2>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  variant="outlined"
                  value={editTitle}
                  onChange={(e) => setEditTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Date"
                    value={editDate}
                    onChange={(newValue) => setEditDate(newValue)}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  minRows={3}
                  placeholder="Description"
                  style={{ width: '100%', padding: '10px' }}
                  value={editDesc}
                  onChange={(e) => setEditDesc(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Location"
                  variant="outlined"
                  value={editLocation}
                  onChange={(e) => setEditLocation(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
              <input type="file"  onChange={(e) => setEditFile(e.target.files[0])} />
              </Grid>
              {/* <Box>
                <img
                  src={`${imgURL}/${modalContent?.file[0].file}`}
                  alt={modalContent?.title}
                  style={{ width: '100%' }}
                />
              </Box> */}
            </Grid>
            <Button style={{ float:'right', padding: '10px' }} variant="contained" color="primary" onClick={handleUpdateEvent}>
              Update
            </Button>
          </Box>
        </Modal>
      </Container>
    </>
  );
};

export default Event;
