import React, { useEffect, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, Edit, GetApp, Visibility } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import { imgURL } from '../service/config';
import { addDesignation, deleteDesignation, getDesignation } from '../service/user.service';

const Designation = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const getdata = async () => {
    try {
      const resp = await getDesignation();
      console.log(resp.data);
      setTableData(resp.data.map((item) => ({ ...item })));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const handleDeleteRow = (row) => {
    setSelectedRow(row);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmationProceed = async () => {
    try {
      await deleteDesignation(tableData[selectedRow.index]._id);
      getdata();
      setDeleteConfirmationOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDesignationUpload = async () => {
    try {
      console.log(title);
      const response = await addDesignation({ title });
      console.log(response);
      getdata();
      setCreateModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      accessorKey: 'title',
      header: 'Title',
      size: 140,
      enableColumnOrdering: false,
      enableSorting: false,
    },
  ];

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Designations</title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Designations
        </Typography>
      </Container>

      <MaterialReactTable
        columns={columns}
        data={tableData}
        editingMode="modal"
        enableEditing
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button color="secondary" onClick={() => setCreateModalOpen(true)} variant="contained">
            Upload New Designation
          </Button>
        )}
      />

      <CreateNewDesignationModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleDesignationUpload}
        onFileChange={handleFileChange}
        onChangeTitle={handleChangeTitle}
      />

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)} maxWidth="xs">
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Are you sure you want to delete this designation?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmationProceed} color="error">
            Delete
          </Button>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CreateNewDesignationModal = ({ open, onClose, onSubmit, onFileChange, onChangeTitle }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create New Designation</DialogTitle>
      <br />

      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Stack sx={{ width: '100%', minWidth: { xs: '300px', sm: '360px', md: '400px' }, gap: '1.5rem' }}>
            <TextField id="outlined-controlled" label="Designation Name" name="title" onChange={onChangeTitle} />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Create New Designation
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Designation;
