import { Action } from "./config";


export const addDocument = async (payload) => {
    const data = await Action.post(`/admin/document`,payload);
    return data.data;
  };
  export const updateDocument = async (id, payload) => {
    const data = await Action.put(`/admin/document/${id}`, payload);
    return data.data;
  };
  

export const getDocument = async () => {
    const data = await Action.get(`/admin/document`);
    return data.data;
  };
  export const deleteDocument = async (id) => {
    const data = await Action.delete(`/admin/document/${id}`);
    return data.data;
  };
  


export const addMagazine = async (payload) => {
    const data = await Action.post(`/admin/magazine`,payload);
    return data.data;
  };
  export const updateMagazine = async (id, payload) => {
    const data = await Action.put(`/admin/magazine/${id}`, payload);
    return data.data;
  };
  

export const getMagazine = async () => {
    const data = await Action.get(`/admin/magazine`);
    return data.data;
  };
  export const deleteMagazine = async (id) => {
    const data = await Action.delete(`/admin/magazine/${id}`);
    return data.data;
  };
  

  export const addVideo = async (payload) => {
    const data = await Action.post(`/admin/video`,payload);
    return data.data;
  };
  export const updateVideo = async (id, payload) => {
    const data = await Action.put(`/admin/video/${id}`, payload);
    return data.data;
  };
  

export const getVideo = async () => {
    const data = await Action.get(`/admin/video`);
    return data.data;
  };
  export const deleteVideo = async (id) => {
    const data = await Action.delete(`/admin/video/${id}`);
    return data.data;
  };

  export const addEvent = async (payload) => {
    const data = await Action.post(`/admin/event`,payload);
    return data.data;
  };
  export const updateEvent = async (id, payload) => {
    const data = await Action.patch(`/admin/event/${id}`, payload);
    return data.data;
  };
  

export const getEvent = async () => {
    const data = await Action.get(`/admin/getallevents`);
    return data.data;
  };
  export const getSingleEvent = async (id) => {
    const data = await Action.get(`/admin/event/${id}`);
    return data.data;
  };
  export const deleteEvent = async (id) => {
    const data = await Action.delete(`/admin/event/${id}`);
    return data.data;
  };

  export const getcontactform = async () => {
    const data = await Action.get(`/admin/contact`);
    return data.data;
  };

  export const deletecontactform = async (id) => {
    const data = await Action.delete(`/admin/deleteform/${id}`);
    return data.data;
  };


  
  
  