import React, { useEffect, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Delete, GetApp } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import ViewDocumentModal from '../components/ViewDocumentModal';
import { imgURL } from '../service/config';
import { addDocument, deleteDocument, updateDocument, getDocument } from '../service/ContentUploads';

const Document = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [file, setFile] = useState(null);
  const [cimage, setCimage] = useState(null);
  const [title, setTitle] = useState('');
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState(null);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false); // State for error popup
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  const getdata = async () => {
    try {
      const resp = await getDocument();
      console.log(resp.data);
      setTableData(
        resp.data.map((item) => {
          return { ...item, userName: item.user.name };
        })
      );
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error(error);
      setLoading(false); // Set loading to false even if an error occurs
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    try {
      if (values.examples) {
        values.examples = values.examples.split(',');
      }
      await updateDocument(tableData[row.index]._id, values);
      getdata();
      exitEditingMode();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteRow = (row) => {
    setDeleteRowIndex(row.index);
    setDeleteConfirmationOpen(true);
  };

  const confirmDeleteRow = async () => {
    try {
      await deleteDocument(tableData[deleteRowIndex]._id);
      getdata();
      setDeleteConfirmationOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelDelete = () => {
    setDeleteRowIndex(null);
    setDeleteConfirmationOpen(false);
  };

  const handleDocumentUpload = async () => {
    try {
      if (!title || !file || !cimage) {
        setErrorPopupOpen(true); // Open the error popup modal if any field is empty
        return;
      }
      setCreateModalOpen(false);
      const formData = new FormData();
      formData.append('title', title);
      formData.append('files', file);
      formData.append('files', cimage);

      const response = await addDocument(formData);
      console.log(response);
      getdata();
   
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleCoverImageChange = (event) => {
    setCimage(event.target.files[0]);
  };

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleOpenViewModal = (documentUrl) => {
    setSelectedDocumentUrl(documentUrl);
    setViewModalOpen(true);
  };

  const handleCloseViewModal = () => {
    setViewModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Documents</title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Documents
        </Typography>
      </Container>

      {loading ? ( // Show loader if loading is true
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      ) : (
        // Render the table when loading is false
        <MaterialReactTable
          columns={[
            {
              accessorKey: 'title',
              header: 'Title',
              size: 140,
              enableColumnOrdering: false,
              enableSorting: false,
            },
            {
              accessorKey: 'file',
              header: 'File',
              enableColumnOrdering: false,
              enableSorting: false,
              size: 10,
              type: 'file',
              Cell: ({ cell }) => (
                <>
                  <IconButton
                    component={Link}
                    href={imgURL + cell.row.original.file[0]}
                    download
                    color="primary"
                    aria-label="Download File"
                  >
                    <GetApp />
                  </IconButton>
                </>
              ),
            },
          ]}
          data={tableData}
          editingMode="modal"
          enableEditing
          onEditingRowSave={handleSaveRowEdits}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          renderTopToolbarCustomActions={() => (
            <Button color="secondary" onClick={() => setCreateModalOpen(true)} variant="contained">
              Upload New Document
            </Button>
          )}
        />
      )}

      <Dialog open={createModalOpen} onClose={() => setCreateModalOpen(false)}>
        <DialogTitle>Create New Document</DialogTitle>
        <DialogContent>
          <Typography>Please upload the document in "docx", "docx", "pdf", or "txt" format</Typography>
          <form onSubmit={handleDocumentUpload}>
            <Stack sx={{ width: '100%', minWidth: { xs: '300px', sm: '360px', md: '400px' }, gap: '1.5rem' }}>
              <TextField id="outlined-controlled" label="Document Name" name="title" onChange={handleChangeTitle} />
              <Typography variant="h5" component="h2">
                Add Document
              </Typography>
              <input type="file" onChange={handleFileChange} />

              <Typography variant="h5" component="h2">
                Add Cover Image
              </Typography>
              <input type="file" accept=".png,.jpg,.jpeg" onChange={handleCoverImageChange} />
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateModalOpen(false)}>Cancel</Button>
          <Button color="secondary" onClick={handleDocumentUpload} variant="contained">
            Create New Document
          </Button>
        </DialogActions>
      </Dialog>

      <ViewDocumentModal open={viewModalOpen} onClose={handleCloseViewModal} documentUrl={selectedDocumentUrl} />

      <Dialog open={deleteConfirmationOpen}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>Are you sure you want to delete this document?</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={confirmDeleteRow} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={errorPopupOpen} onClose={() => setErrorPopupOpen(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography>Please fill in all the fields.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorPopupOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Document;
