import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Viewer } from 'react-doc-viewer'; // Import the Viewer component

const ViewDocumentModal = ({ open, onClose, documentUrl }) => {
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>View Document</DialogTitle>
      <DialogContent>
        <Viewer
          key={documentUrl} // Ensure re-rendering when the documentUrl changes
          documents={[
            {
              uri: documentUrl,
              // Pass any additional options for the viewer here (e.g., zoom, print, etc.)
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewDocumentModal;
