import React, { useEffect, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, Edit, GetApp, Visibility } from '@mui/icons-material';
import ReplyIcon from '@mui/icons-material/Reply';

import { Helmet } from 'react-helmet-async';
import { imgURL } from '../service/config';
import { deletecontactform, getcontactform } from '../service/ContentUploads';

const ContactForm = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState('');
  const [messageModalOpen, setMessageModalOpen] = useState(false);

  const getdata = async () => {
    try {
      const resp = await getcontactform();
      console.log(resp.data);
      setTableData(resp.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const handleDeleteRow = async (row) => {
    try {
      await deletecontactform(tableData[row.index]._id);
      getdata();
    } catch (error) {
      console.error(error);
    }
  };

  

  const openMessageModal = (message) => {
    console.log("message1",message)
    setSelectedMessage(message);
    setMessageModalOpen(true);
  };
  

  const columns = [
    {
      accessorKey: 'name',
      header: 'Name',
      size: 140,
      enableColumnOrdering: false,
      enableSorting: false,
    },
    {
      accessorKey: 'phone',
      header: 'Phone',
      size: 140,
      enableColumnOrdering: false,
      enableSorting: false,
    },
    {
      accessorKey: 'email',
      header: 'Email',
      size: 140,
      enableColumnOrdering: false,
      enableSorting: false,
    },
    {
      accessorKey: 'message',
      header: 'Message',
      size: 140,
      enableColumnOrdering: false,
      enableSorting: false,
      Cell: ({ cell }) => (
        <>
          <IconButton
            onClick={() => openMessageModal(cell.row.original.message)}
            color="primary"
            aria-label="View Message"
          >
            <Visibility />
          </IconButton>
        </>
      ),
    },
    {
        accessorKey: 'email',
        header: 'Reply',
        size: 140,
        enableColumnOrdering: false,
        enableSorting: false,
        Cell: ({ cell }) => (
          <>
      <IconButton
  href={`mailto:${cell.row.original.email}`}
  color="primary"
  aria-label="View Message"
>
  <ReplyIcon />
</IconButton>
          </>
        ),
      },
  ];

  const handleCloseMessageModal = () => {
    setSelectedMessage('');
    setMessageModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Contact Form</title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Contact Form
        </Typography>
      </Container>

      <MaterialReactTable
        columns={columns}
        data={tableData}
        renderRowActions={({ row }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                  <Delete />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="View Message">
                <IconButton
                  color="primary"
                  aria-label="View Message"
                  onClick={() => openMessageModal(row.message)}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          
      />

      <MessageModal open={messageModalOpen} onClose={handleCloseMessageModal} message={selectedMessage} />
    </>
  );
};

const MessageModal = ({ open, onClose, message }) => {
    console.log("meesgae",message)
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Message</DialogTitle>
      <DialogContent>
  <Typography>{message}</Typography>
</DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactForm;
