import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography } from '@mui/material';
import { getdashboard } from '../service/user.service';
import { AppWidgetSummary } from '../sections/@dashboard/app';
// import BarGraph from '../components/Bargraph';

const DashboardAppPage = () => {
  const [payload, setPayload] = useState({
    pending: 0,
    all: 0,
    deleted: 0,
    accepted: 0,
  });

  // const [eventData, setEventData] = useState([]); // State for storing event data

  const getdata = async () => {
    try {
      const resp = await getdashboard();
      console.log(resp.data);
      setPayload(resp.data);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  // useEffect(() => {
  //   // Set event data when payload is updated
  //   const data = [
  //     {
  //       _id: 'event1',
  //       title: 'Event 1',
  //       eventusers: ['user1', 'user2', 'user3'],
  //       date: '2023-01-01',
  //     },
  //     {
  //       _id: 'event2',
  //       title: 'Event 2',
  //       eventusers: ['user4', 'user5'],
  //       date: '2023-02-01',
  //     },
  //   ];
  //   setEventData(data);
  // }, [payload]);

  return (
    <>
      <Helmet>
        <title>Dashboard | UAW</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="All Users"
              total={payload.all === 0 ? 0 : payload.all}
              icon="mdi:users-group"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Accepted Users"
              total={payload.accepted === 0 ? 0 : payload.accepted}
              color="info"
              icon="mdi:users-check"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Pending Users"
              total={payload.pending === 0 ? 0 : payload.pending}
              color="warning"
              icon="mdi:account-pending"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Deleted Users"
              total={payload.deleted === 0 ? 0 : payload.deleted}
              color="error"
              icon="mdi:users-minus"
            />
          </Grid>
        </Grid>

        {/* <BarGraph eventData={eventData} />  */}
        {/* Render the BarGraph component */}
      </Container>
    </>
  );
};

export default DashboardAppPage;
